<template>
  <div id="problem-list">
    <!-- dialog -->

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchProblems"
    ></delete-confirmation>



    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="problem-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="fetchNewCodes()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Fetch all ICD10Codes</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedProblem"
        :headers="tableColumns"
        :items="icd10List"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isProblemFormActive = !isProblemFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="selectItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>Disable</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import problemService from '@/services/ProblemService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useProblemList from '@/store/useProblemService'

export default {
  components: { DeleteConfirmation },
  setup() {
    const PROBLEM_APP = 'app-problem'

    // Register module
    if (!store.hasModule(PROBLEM_APP)) store.registerModule(PROBLEM_APP, problemService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROBLEM_APP)) store.unregisterModule(PROBLEM_APP)
    })

    const {
      icd10List,
      tableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedProblem,
      readCode,

      fetchProblems,
      deleteProblem,
      fetchICD10Codes,
    } = useProblemList()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isDeleteDialogActive = ref(false)
    const isProblemFormActive = ref(false)

    return {
      icd10List,
      tableColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedProblem,
      isProblemFormActive,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      readCode,

      deleteProblem,
      fetchProblems,
      fetchICD10Codes,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    this.fetchProblems()
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      console.log(this.selectedItem)
    },
    submitDelete(problemDTO) {
      this.deleteProblem(problemDTO)
    },
    fetchNewCodes(){
      this.fetchICD10Codes()
    }
  },
}
</script>
